import React from 'react';
import { Provider } from 'mobx-react';
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { LastLocationProvider } from 'react-router-last-location';

import '../index.css';
import App from './App';
import RootStore from '../store';
import Monitoring from "../utils/Monitoring";


if (process.env.NODE_ENV === "development" ) {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    onlyLogs: true,
    trackAllPureComponents: true,
    titleColor: "green",
    diffNameColor: "darkturquoise",
    include: [/ShopHeader/]
  });
}

const routingStore = new RouterStore();
 
const stores = {
  // Key can be whatever you want
  routing: routingStore,
  rootStore: RootStore
};

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, routingStore);

Monitoring.setup(history);


const AppWithStore = () => (
  <Provider {...stores}>
    <Router history={history}>
      <LastLocationProvider watchOnlyPathname={true}>
        <App />
      </LastLocationProvider>
    </Router>
  </Provider>
)

export default AppWithStore;