import gql from "graphql-tag";

export default gql`
  query getOrderById($id: String! ) {
    getOrderById(id: $id) {
    orderId
    city
    countryCode
    shopId
    shopPhone
    shopName
    totalPrice
    items {
      id
      images
      price
      title
    }
  }
}
`;
