
const {
    REACT_APP_AWS_REGION,
    REACT_APP_APPSYNC_ENDPOINT,
    REACT_APP_APPSYNC_AUTH_TYPE,
    REACT_APP_COGNITO_IDENTITY_POOL_ID,
    REACT_APP_COGNITO_USER_POOL_ID,
    REACT_APP_COGNITO_USER_POOL_WEBCLIENT_ID,
    REACT_APP_S3_IMAGES_BUCKET
} = process.env;

export default {
    'aws_appsync_graphqlEndpoint': REACT_APP_APPSYNC_ENDPOINT,
    'aws_appsync_region': REACT_APP_AWS_REGION,
    'aws_appsync_authenticationType': REACT_APP_APPSYNC_AUTH_TYPE,
    Auth: {
        identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
        region: REACT_APP_AWS_REGION,
        userPoolId: REACT_APP_COGNITO_USER_POOL_ID, 
        userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_WEBCLIENT_ID,
    },
    Storage: {
        AWSS3: {
            bucket: REACT_APP_S3_IMAGES_BUCKET,
            region: REACT_APP_AWS_REGION
        }
    }
}