import { action, observable } from "mobx";
import { Dimensions } from "react-native";

const { width } = Dimensions.get('window');


/**
 * Stores to manage session data
 */
class SessionStore {

    rootStore = null;
    api = null;
    
    @observable numColumns = null
    @observable cardDimensions = null
    @observable imageDimensionsForCarousel = null


    constructor(rootStore, api) {
        this.rootStore = rootStore;
        this.api = api;
        this.numColumns = this._getNumberOfColumns();
        this.cardDimensions = this._getCardDimensions();
    }

    _getNumberOfColumns() {
        let numColumns = 2;
    
        if (width > 600 && width < 1000) {
            numColumns = 3
        }
    
        if (width > 1000) {
            numColumns = 4
        }
    
        return numColumns;
    }

    @action.bound
    getCarouselDimensions() {
        if (!this.imageDimensionsForCarousel) {
            this.imageDimensionsForCarousel = this._getImageDimensionsForCarousel();
        }

        return this.imageDimensionsForCarousel;
    }
    
    _getImageDimensionsForCarousel() {
        const WIDTH_CAROUSEL_RATIO = {
            2: 1,
            3: 1.9,
            4: 2.3
        }
    
        let ratio = WIDTH_CAROUSEL_RATIO[this.numColumns];

        if (this.numColumns === 4 && width > 1000 && width < 1100) {
            ratio = 2
        }
    
        if (this.numColumns === 4 && width > 1100) {
            ratio = 2.8
        }
        
        let cardWidth = Math.round(width / ratio);
        let cardHeight = cardWidth + 20;

        return { height: cardHeight, width: cardWidth };
    }
    
    _getCardDimensions() {
        const WIDTH_CARD_RATIO = {
            2: 2.16,
            3: 3.2,
            4: 4.2
        }
    
        let ratio = WIDTH_CARD_RATIO[this.numColumns];
    
        if (this.numColumns === 4 && width > 1100) {
            ratio = 4.5
        }
        
        let cardWidth = Math.round(width / ratio);
        let cardHeight = cardWidth + 20;
    
        return { height: cardHeight, width: cardWidth };
    }
}

export default SessionStore;