import { observable, action, computed, toJS, set, reaction } from "mobx";

import Database from "../../utils/Database";
import Monitoring from "../../utils/Monitoring";

/**
 * Manages user's cart
 */
class CartStore {

    rootStore = null;
    api = null;

    @observable itemIds = [];
    @observable shopCarts = {};
    @observable shopIds = [];
    @observable totalPrice = 0;
    @observable firstRun = true;

    constructor(rootStore, api) {
        this.rootStore = rootStore;
        this.api = api;

        reaction(
            () => this.itemIds.length,
            () => {
                if (this.firstRun) {
                    Database
                        .get("cartStore")
                        .then(res => {
                            const existingStore = res;
                            
                            if (existingStore) {
                                set(this, existingStore);
                            }

                            this.firstRun = false;
                        })
                        .catch(err => {
                            Monitoring.recordError(err);
                            this.firstRun = false;
                        })
                } else {
                    const { itemIds, shopIds, shopCarts } = toJS(this);
                    Database.set("cartStore", { itemIds, shopIds, shopCarts });
                }
            },
            {
                fireImmediately: true
            }
        )
    }

    @action.bound
    async createOrder(shopCart) {
        try {
            const { items, shopId, city, totalPrice, countryCode, shopPhone, shopName } = shopCart;
            const newItems = [];

            items.forEach(item => {
                const { id, title, images, price, category } = toJS(item);
                newItems.push({ id, title, images, price, category });
            });

            const result = await this.api.Order.createOrder({
                shopId,
                countryCode,
                city,
                shopName,
                shopPhone,
                totalPrice,
                items: newItems
            });

            return result.orderId
        } catch (error) {
            Monitoring.recordError(error.errors[0]);
        }
    }

    @action.bound
    addToCart(item) {
        const { id, shopId, shopName, shopPhone, category, city, countryCode, price} = item;

        if (!this.itemIds.includes(id)) {
            this.itemIds.push(id);

            if (!this.shopIds.includes(shopId)) {

                this.shopCarts[shopId] = {
                    shopId,
                    shopName,
                    shopPhone,
                    city,
                    category,
                    countryCode,
                    totalPrice: 0,
                    items: []
                }
            }


            this.shopCarts[shopId].items.unshift(item);
            this.shopCarts[shopId].totalPrice += price;
            
            // put shopId of last added item at the start of array
            this._removesShopIdFromList(shopId);
            this.shopIds.unshift(shopId);
        }
    }

    @action.bound
    removesShopCart({ shopId, items}) {
        items.forEach(item => this._removesItemFromList(item.id));
        this._removesShopIdFromList(shopId);
        delete this.shopCarts[shopId];
    }

    @action.bound
    _removesShopIdFromList(shopId) {
        this.shopIds = this.shopIds.filter(shopIDInCart => shopIDInCart !== shopId);
    }

    @action.bound
    _removesItemFromList(itemId){
        const index = this.itemIds.indexOf(itemId);
        this.itemIds.splice(index, 1);
    }


    @action.bound
    removesItemFromShopCart(itemId, shopId) {
        const shopCart = this.shopCarts[shopId];

        this._removesItemFromList(itemId);

        if (shopCart.items.length === 1) {
            this._removesShopIdFromList(shopId);
            delete this.shopCarts[shopId];
        } else {
            const newItems = shopCart.items;
            shopCart.items.find((item, idx) => {
                if (item.id === itemId) {
                    shopCart.totalPrice -= item.price;
                    newItems.splice(idx, 1);
                    
                    return true;
                }
    
                return false;
            });

            shopCart.items = [...newItems];
        } 
    }

    @computed get getTotalPrice() {
        return this.totalPrice;
    }

    @computed get getCartItems() {
        const shopCarts = [];
        Object.values(this.shopCarts)
            .map(item => shopCarts.push(toJS(item)));

        return shopCarts;
    }

    @computed get numberOfCartItems() {
        return this.itemIds.length;
    }

    @computed get numberOfShopCarts() {
        return this.shopIds.length;
    }
}

export default CartStore;