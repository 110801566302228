import { observable, action, computed, toJS, set, reaction } from "mobx";

import Monitoring from "../../utils/Monitoring";
import Database from "../../utils/Database";

/**
 * Manages items saved by user
 */
class SavedItemStore {

    rootStore = null;
    api = null;

    @observable savedItems = [];
    // @observable savedItemIds = [];
    @observable firstRun = true;
    @observable savedItemIds = {};

    constructor(rootStore, api) {
        this.rootStore = rootStore;
        this.api = api;

        reaction(
            () => this.savedItems.length,
            () => {
                if (this.firstRun) {
                    Database
                        .get("savedItemStore")
                        .then(res => {
                            const existingStore = res;
                            
                            if (existingStore) {
                                set(this, existingStore);
                            }

                            this.firstRun = false;
                        })
                        .catch(err => {
                            Monitoring.recordError(err);
                            this.firstRun = false;
                        })
                } else {
                    const { savedItems, savedItemIds } = toJS(this);
                    Database.set("savedItemStore", { savedItems, savedItemIds });
                }
            },
            {
                fireImmediately: true
            }
        )
    }

    @computed
    get getSavedItems() {
        return toJS(this.savedItems.slice());
    } 

    hasItem(itemId) {
        return this.savedItemIds[itemId] ? true : false;
    }

    @action.bound
    updateSavedStatus(item, currentSavedStatus) {
        if (currentSavedStatus) {
            this.rootStore.savedItemStore.removeSavedItem(item.id);
            this.isSaved = false;
        } else {
            this.rootStore.savedItemStore.saveItem(item);
            this.isSaved = true;
        }
    }

    @action.bound
    saveItem(item) {
        if (!this.savedItemIds[item.id]) {
            this.savedItems.unshift(item);
            this.savedItemIds[item.id] = item.id;
        }
    }

    @action.bound
    removeSavedItem(itemId) {
        this.savedItems.forEach((item, idx) => {
            if (item.id === itemId) {
                this.savedItems.splice(idx, 1);
                return;
            }
        });

        delete this.savedItemIds[itemId];
    }

    @computed get numberOfSavedItems() {
        return this.savedItems.length;
    }
}

export default SavedItemStore;