import gql from "graphql-tag";

export default gql`
  query getShopById($id: String!) {
    getShopById(id: $id) {
      shopId
      shopName
      logo,
      description,
      city
      countryCode,
      phone,
      availableItemsCount
    }
  }
`;
