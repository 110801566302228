import gql from "graphql-tag";

export default gql`
    query getShopItemCategoriesByShopId($id: String!, $nextToken: String, $limit: Int) {
        getShopItemCategoriesByShopId(id: $id, nextToken: $nextToken, limit: $limit) {
            nextToken
            items {
                category
                availableItemsCount
            }
        }
    }   
`;
