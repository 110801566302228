import { observable, action, computed, toJS, set, reaction } from "mobx";

import Monitoring from "../../utils/Monitoring";
import Database from "../../utils/Database";

/**
 * Manages recently viewed shops by user
 */
class recentShopstore {

    rootStore = null;
    api = null;

    @observable recentShops = [];
    @observable recentShopIds = {};
    @observable firstRun = true;

    constructor(rootStore, api) {
        this.rootStore = rootStore;
        this.api = api;

        reaction(
            () => this.recentShops.length,
            () => {
                if (this.firstRun) {
                    Database
                        .get("recentShopStore")
                        .then(res => {
                            const existingStore = res;
                            
                            if (existingStore) {
                                set(this, existingStore);
                            }

                            this.firstRun = false;
                        })
                        .catch(err => {
                            Monitoring.recordError(err);
                            this.firstRun = false;
                        })
                } else {
                    const { recentShops, recentShopIds } = toJS(this);
                    Database.set("recentShopStore", { recentShops, recentShopIds });
                }
            },
            {
                fireImmediately: true
            }
        )
    }

    @computed get getrecentShops() {
        return this.recentShops.slice();
    }

    @action.bound
    resetRecentlyViewedShops() {
        this.recentShops = [];
        this.recentShopIds = {};
    }

    @action.bound
    addRecentShop(shop) {
        if (!this.recentShopIds[shop.id]) {
            this.recentShops.unshift(shop);
            this.recentShopIds[shop.id] = shop.id;
        } else {
            this.recentShops.forEach((recentShop, idx) => {
                if (recentShop.id === shop.id) {
                    this.recentShops.splice(idx, 1);
                    return;
                }
            });
            
            this.recentShops.unshift(shop);
        }

        if (this.recentShops.length > 10) {
            const removedShop = this.recentShops.pop();
            delete this.recentShopIds[removedShop.id];
        }
    }

    @action.bound
    removeRecentShop(shopId) {
        this.recentShops.forEach((recentShop, idx) => {
            if (recentShop.id === shopId) {
                this.recentShops.splice(idx, 1);
                return;
            }
        });

        delete this.recentShopIds[shopId];
    }
}

export default recentShopstore;