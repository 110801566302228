export default {
    "beauty#woman": [
        { id: "makeup", title: "Maquillage" },
        { id: "hair", title: "Cheveux et Accéssoires" },
        { id: "wig", title: "Perruques et Extensions" },
        { id: "depilatory", title: "Epilation" },
        { id: "body-care", title: "Soins du corps" },
        { id: "face-care", title: "Soins du visage" },
        { id: "perfume", title: "Parfums" }
    ],

    "beauty#woman#hair": [
        { id: "care", title: "Soins des cheveux" },
        { id: "accessories", title: "Accessoires cheveux" }
    ],

    "beauty#woman#wig": [
        { id: "extension", title: "Extension" },
        { id: "afro", title: "Perruque Afro" },
        { id: "braided", title: "Perruque Tressée" },
        { id: "indian", title: "Perruque Indienne" },
        { id: "brasilian", title: "Perruque Bresilienne" },
        { id: "peruvian", title: "Perruque Peruvienne" },
        { id: "synthetic", title: "Perruque Synthétique" },
        { id: "other", title: "Autres" }
    ],

    "beauty#woman#hair#accessories": [
        { id: "brush", title: "Brosse à cheveux" },
        { id: "comb", title: "Peignes" },
        { id: "curling-iron", title: "Fer à friser" },
        { id: "straightener", title: "Fer à lisser" },
        { id: "dryer", title: "Sèche cheveux" }
    ],

    "beauty#woman#hair#care": [
        { id: "wax", title: "Cire à cheveux" },
        { id: "coloration", title: "Coloration des cheveux" },
        { id: "cream", title: "Crème coiffante" },
        { id: "gel", title: "Gels de coiffage" },
        { id: "oil", title: "Huiles pour coiffage" },
        { id: "mask", title: "Masques capillaire" },
        { id: "foam", title: "Mousse pour cheveux" },
        { id: "serum", title: "Serum pour cheveux" },
        { id: "shampoo", title: "Shampoing" },
        { id: "after-shampoo", title: "Après-Shampoing" },
        { id: "spray", title: "Spray coiffant" },
    ],

    "beauty#woman#depilatory": [
        { id: "depilator", title: "Epilateur" },
        { id: "cream", title: "Crème épilatoire" },
        { id: "wax", title: "Cire épilatoire" },
        { id: "shaver", title: "Rasoir" },
        { id: "accessories", title: "Accessoires d'épilation" }
    ],

    "beauty#woman#body": [
        { id: "day-cream", title: "Crème de jour" },
        { id: "night-cream", title: "Crème de nuit" },
        { id: "bath-kit", title: "Coffret pour le bain" },
        { id: "moisturizer", title: "Crème hydradante" },
        { id: "hand-cream", title: "Crème pour les mains" },
        { id: "shower-gel", title: "Gel douche" },
        { id: "scrub", title: "Gommage pour le corps" },
        { id: "body-oil", title: "Huile pour le corps" },
        { id: "body-lotion", title: "Lotion pour le corps" },
        { id: "hand-lotion", title: "Lotion pour les mains" },
        { id: "foot-lotion", title: "Lotion pour les pieds" },
        { id: "bath-salt", title: "Sel de bain" }
    ],

    "beauty#woman#face": [
        { id: "cleaning-brush", title: "Brosse nettoyante" },
        { id: "kit", title: "Coffrets soins du visage" },
        { id: "cream", title: "Crème pour le visage" },
        { id: "makeup-remover", title: "Démaquillant" },
        { id: "scrub", title: "Gommage pour le visage" },
        { id: "oil", title: "Huile pour le visage" },
        { id: "serum", title: "Sérum pour le visage" },
    ],

    "beauty#woman#eye": [
        { id: "cream", title: "Crèmes contour des yeux" },
        { id: "makeup-remover", title: "Démaquillant" },
        { id: "mask", title: "Masque pour le yeux" },
        { id: "serum", title: "Sérum pour les yeux" },
    ],

    "beauty#woman#perfume": [
        { id: "perfume", title: "Eau de parfum" },
        { id: "cologne", title: "Eau de toilette" }
    ],

    "beauty#woman#makeup": [
        { id: "face", title: "Visage" },
        { id: "eye", title: "Yeux" },
        { id: "eyebrow", title: "Sourcils" },
        { id: "lips", title: "Lèvres" },
        { id: "ongles", title: "Ongles" },
        { id: "accessories", title: "Outils & Accessoires de maquillage" }
    ],

    "beauty#woman#makeup#face": [
        { id: "concealer", title: "Anticerne" },
        { id: "base", title: "Base de teint" },
        { id: "powder", title: "Fond de teint" },
        { id: "blush", title: "Blush" },
        { id: "kit", title: "Coffrets maquillage" },
        { id: "spray", title: "Spray fixant" },
        { id: "stick", title: "Stick pour les contours" }
    ],

    "beauty#woman#makeup#eye": [
        { id: "base", title: "Base pour les yeux" },
        { id: "pencil", title: "Crayons pour les yeux" },
        { id: "liner", title: "Eye-liner" },
        { id: "shadow", title: "Fard à paupières" },
        { id: "shadow-pallet", title: "Palettes de fard à paupières" },
        { id: "fake-eyelashes", title: "Faux cils" },
        { id: "mascara", title: "Mascara" }
    ],

    "beauty#woman#makeup#eyebrow": [
        { id: "pencil", title: "Crayons sourcils" },
        { id: "gel", title: "Gel sourcils" },
        { id: "powder", title: "Poudre sourcils" },
        { id: "tint", title: "Teinture sourcils" },
        { id: "kit", title: "Kit sourcils" }
    ],

    "beauty#woman#makeup#lips": [
        { id: "lipstick", title: "Rouge à lèvres" },
        { id: "balm", title: "Baume à Lèvres" },
        { id: "gloss", title: "Brillant à Lèvres" },
        { id: "contour", title: "Contours des lèvres" },
        { id: "kit", title: "Kit pour les lèvres" },
        { id: "care", title: "Soin pour les lèvres" }
    ],

    "beauty#woman#makeup#nail": [
        { id: "polish", title: "Vernis à ongles" },
        { id: "false", title: "Faux ongles" },
        { id: "art", title: "Nail art" },
        { id: "care", title: "Soin des ongles" }
    ],

    "beauty#woman#makeup#accessories": [
        { id: "brush", title: "Pinceau" },
        { id: "brush-kit", title: "Kit de pinceaux" },
        { id: "sponge", title: "Eponge" },
        { id: "storage", title: "Box de rangement" },
        { id: "file", title: "Lime à ongles" }
    ]
}