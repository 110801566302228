import { observable, action, computed  } from "mobx";

import Item from "./Item";

import Monitoring from "../../utils/Monitoring";


/**
 * Manages items store
 */
class ItemStore {
    rootStore = null;
    api = null;
    
    @observable items = [];
    @observable nextToken = null;
    @observable isLoadingMore = false;
    @observable LOADING_SINGLE_ITEM = false;
    @observable IS_LOADING_ITEMS = true;
    @observable ITEM_NOT_FOUND = false;
    @observable FAILED_TO_LOAD_ITEMS = false;
    @observable currentItem = null;

    constructor(rootStore, api) {
        this.rootStore = rootStore;
        this.api = api;
    }

    @action.bound
    async loadItems(category) {
        this.IS_LOADING_ITEMS = true;
        try {
            this.IS_LOADING_ITEMS = true;
            let result;
            
            if (!category) {
                result = await this.fetchData();
            } else {
                result = await this.fetchDataByCategory(category);
            }

            this.items = this.generateItems(result);

            this.IS_LOADING_ITEMS = false;
            this.FAILED_TO_LOAD_ITEMS = false;
        } catch (error) {
            Monitoring.recordError(error);
            // inform developer
            this.IS_LOADING_ITEMS = false;
            this.FAILED_TO_LOAD_ITEMS = true;
        }
    }

    setItems(products) {
        this.items = this.generateItems(products.items);
        this.nextToken = products.nextToken;

        this.IS_LOADING_ITEMS = false;
        this.FAILED_TO_LOAD_ITEMS = false;
    }

    @action.bound
    async loadMoreItems(category) {
        try {
            if (this.nextToken && !this.isLoadingMore) {
                this.isLoadingMore = true;
                let result;

                if (!category) {
                    result = await this.fetchData(this.nextToken);
                } else {
                    result = await this.fetchDataByCategory(category, this.nextToken);
                }

                const items = this.generateItems(result);
                this.items = this.items.concat(items);

                this.isLoadingMore = false;
            }
        } catch (error) {
            // inform developer
            this.isLoadingMore = false;
            this.FAILED_TO_LOAD_ITEMS = true;
        }
    }

    generateItems(items) {
        const products = [];
        items.forEach(item => {
            const product = new Item(this);
            product.updateFromJson(item);
            products.push(product);
        });

        return products;
    }

    async fetchData(nextToken) {
        const result = await this.api.Item.getItemsByShopId(this.rootStore.shopStore.id, nextToken);
        this.nextToken = result.nextToken;

        return result.items;
    }

    async fetchDataByCategory(category, nextToken) {
        const result = await this.api.Item.getItemsByShopByCategory(this.rootStore.shopStore.id, category, nextToken);
        this.nextToken = result.nextToken;

        return result.items;
    }

    @action.bound
    async fetchItem(id) {
        this.ITEM_NOT_FOUND = false;
        this.LOADING_SINGLE_ITEM = true;
        this.currentItem = null;

        const result = await this.api.Item.getItemById(id);
        let item;
        if (result) {
            item = new Item(this);
            item.updateFromJson(result);

            this.currentItem = item;
            this.ITEM_NOT_FOUND = false;
            this.LOADING_SINGLE_ITEM = false;
        } else {
            this.ITEM_NOT_FOUND = true;
            this.LOADING_SINGLE_ITEM = false;
        }

        return item; 
    }

    formatPrice(price, currency) {
        return `${String(price).replace(/(.)(?=(\d{3})+$)/g,'$1 ')} ${currency}`;
    }

    /**
     * Get list of all ITEMS in the store
     */
    @computed get getItems() {
        const jsonItems = [];
        this.items.slice().forEach( item => jsonItems.push(item.asJson));
        
        return jsonItems;
    }

    /**
     * Gets item from local store
     * @param {String} id unique identifier of the item
     */
    @action.bound
    getItemById(id) {
        return this.items.slice().find(item => item.id === id);
    }

    async uploadImages(data) {
        const images = [];
        
        for (const key of Object.keys(data).sort((a,b) => a - b)) {
            const result = await this.api.Storage.uploadImage(data[key]);
            images.push(result.key);
        }
        
        return images;
    }
}

export default ItemStore;