import { getAllCountries } from 'react-native-country-picker-modal';
import * as RNLocalize from "react-native-localize";

const { REACT_APP_IMAGE_HANDLER_ENDPOINT, REACT_APP_S3_IMAGES_BUCKET } = process.env;

const DEFAULT_LANGUAGE_TAG = "fr-CM"
const SUPPORTED_LANGUAGES = ['en', 'fr'];
const LANGUAGE_TAG_TO_LANG_CODE = {
    "en": "eng",
    "fr": "fra"
}

const previousCountries = {}

class CommonUtils {
    /**
     * Gets Country info from country code
     * 
     * @param {String} countryCode 
     * @returns {{currencyCode: String, countryName: String, currencyName: String}} 
    */
    async getCountryInfos(countryCode) {
        if (previousCountries[countryCode]) {
            return previousCountries[countryCode]
        }

        const localize = RNLocalize.findBestAvailableLanguage(SUPPORTED_LANGUAGES);
        const data = await getAllCountries(null, LANGUAGE_TAG_TO_LANG_CODE[localize.languageTag])
        const country = data.find(item => item.cca2 === countryCode);
        const currencyCode = country.currency[0];

        const result = {
            currencyCode: country.currency[0],
            countryName: country.name,
            currencyName: this.getCountryCurrency(currencyCode)
        };

        previousCountries[countryCode] = result;
        
        return result;
    }

    /**
     * 
     * @param {String} currencyCode 
     * @returns {String} currencyName
    */
    getCountryCurrency(currencyCode) {
        const params = { style: 'currency', currency: currencyCode };

        return new Intl.NumberFormat(DEFAULT_LANGUAGE_TAG, params)
                    .format(0).replace('0', '')
                    .trim()
    }

    generateLogoUri(imageS3Key) {
        return this.generateImageURI(imageS3Key, 100, 100);
    }

    generateOrderUri(imageS3Key) {
        return this.generateImageURI(imageS3Key, 100, 100);
    }

    
    generatePlaceholderImageURI(imageS3Key) {
        return this.generateImageURI(imageS3Key, 50, 50);
    }

    generateCardImageURI(imageS3Key) {
        return this.generateImageURI(imageS3Key, 500, 500);
    }

    generateImageURI(imageS3Key, width, height) {
        const request = {
            "bucket": REACT_APP_S3_IMAGES_BUCKET,
            key: "public/" + imageS3Key,
            "edits": {
                "resize": {
                    "width": width,
                    "height": height
                }
            }
        };

        return this.generateEncodedURL(request);
    }

    generateEncodedURL(request) {
        const buff = new Buffer(JSON.stringify(request));
        const base64data = buff.toString('base64');

        return REACT_APP_IMAGE_HANDLER_ENDPOINT + "/" + base64data;
    }
}

export default new CommonUtils();