import { observable, action, computed, toJS, set, reaction } from "mobx";

import Monitoring from "../../utils/Monitoring";
import Database from "../../utils/Database";

/**
 * Manages recently viewed items saved by user
 */
class RecentlyItemStore {

    rootStore = null;
    api = null;

    @observable recentlyItems = [];
    @observable recentlyItemIds = {};
    @observable firstRun = true;

    constructor(rootStore, api) {
        this.rootStore = rootStore;
        this.api = api;

        reaction(
            () => this.recentlyItems.length,
            () => {
                if (this.firstRun) {
                    Database
                        .get("recentItemStore")
                        .then(res => {
                            const existingStore = res;
                            
                            if (existingStore) {
                                set(this, existingStore);
                            }

                            this.firstRun = false;
                        })
                        .catch(err => {
                            Monitoring.recordError(err);
                            this.firstRun = false;
                        })
                } else {
                    const { recentlyItems, recentlyItemIds } = toJS(this);
                    Database.set("recentItemStore", { recentlyItems, recentlyItemIds });
                }
            },
            {
                fireImmediately: true
            }
        )
    }

    @computed get getRecentlyItems() {
        return this.recentlyItems.slice();
    }

    @action.bound
    resetRecentlyViewedItems() {
        this.recentlyItems = [];
        this.recentlyItemIds = {};
    }

    @action.bound
    addRecentItem(item) {
        if (!this.recentlyItemIds[item.id]) {
            this.recentlyItems.unshift(item);
            this.recentlyItemIds[item.id] = item.id;
        } else {
            this.recentlyItems.forEach((recentItem, idx) => {
                if (recentItem.id === item.id) {
                    this.recentlyItems.splice(idx, 1);
                    return;
                }
            });
            
            this.recentlyItems.unshift(item);
        }

        if (this.recentlyItems.length > 10) {
            const removedItem = this.recentlyItems.pop();
            delete this.recentlyItemIds[removedItem.id];
        }
    }

    @action.bound
    removeRecentItem(itemId) {
        this.recentlyItems.forEach((recentItem, idx) => {
            if (recentItem.id === itemId) {
                this.recentlyItems.splice(idx, 1);
                return;
            }
        });

        delete this.recentlyItemIds[itemId];
    }
}

export default RecentlyItemStore;