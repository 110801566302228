import { action, observable } from "mobx";

import ShopItemCategory from "./shopItemCategory";
import Monitoring from "../../utils/Monitoring";


class ShopItemCategoryStore {
    rootStore = null;
    api = null;

    @observable shopItemCategoryIds = new Set([]);
    @observable shopItemCategories = [];
    @observable nextToken = null;
    @observable filteredCategory = {};
    @observable selectedCategory = {};
    @observable prevSelectedCategory = {};
    @observable shopItemFilters = null;
    @observable GENERATING_SHOP_ITEM_FILTERS = true;

    constructor(rootStore, api) {
        this.rootStore = rootStore;
        this.api = api;
    }

    @action.bound
    setFilteredCategory(filteredCategory) {
        this.filteredCategory = filteredCategory;
    }

    @action.bound
    setSelectedCategory(selectedCategory) {
        this.selectedCategory = selectedCategory;
    }

    @action.bound
    setPrevSelectedCategory(prevSelectedCategory) {
        this.prevSelectedCategory = prevSelectedCategory;
    }


    getShopItemCategoryFromId(categoryId) {
        const shopItemCategory = this.shopItemCategories.find(item => item.categoryId = categoryId);
        
        if (shopItemCategory) {
            return shopItemCategory.asJson
        }
    }


    @action.bound
    async loadShopItemCategories(shopId) {
        this.filteredCategory = {};
        this.shopItemFilters = {};
        this.shopItemCategories = await this.api.ShopItemCategory.getShopItemCategoriesByShopId(shopId);
    }

    @action.bound
    setShopItemCategories(itemCategories) {
        const shopItemCategories = [];
    
        itemCategories.items.forEach(item => {
            const shopItemCategory  = new ShopItemCategory(this);
            shopItemCategory.updateFromJson(item)
            
            this.shopItemCategoryIds.add(item.category);
            shopItemCategories.push(shopItemCategory);
        });

        this.shopItemCategories = shopItemCategories;
    }

    @action.bound
    generateShopItemFilters() {
        if (!this.shopItemFilters) {
            this.generateFilterCatalog();
        }
    }


    @action.bound
    generateFilterCatalog() {
        try {
            this.GENERATING_SHOP_ITEM_FILTERS = true;

            const filterCount = {};
            const filters = {};
        
            this.shopItemCategories.forEach(item => {
                const productType = item.asJson;
                const values = productType.categoryId.split("#");
                let prevId;
                
                values.forEach((value, idx) => {
                    let id;
                    
                    if (idx === 0) {
                        id = value
                    } else {
                        id = prevId + "#" + value
                    }
                    
                    prevId = id;
                    
                    if (!filterCount[id]) {
                        filterCount[id] = { count: productType.availableItemsCount }
                    } else {
                        filterCount[id].count = filterCount[id].count + productType.availableItemsCount;
                    }
                })
            });
        
            this.shopItemCategories.forEach(item => {
                const productType = item.asJson;
                const values = productType.categoryId.split("#");
                let previousId;
                
                values.forEach((value, idx) => {
                    if (idx === 0) {
                        let itemCatalog = { ...this.rootStore.itemCatalogStore.getItemCatalog(value) };

                        itemCatalog.name = itemCatalog.title;
                        itemCatalog.availableItemsCount = filterCount[value].count;
                    
                        if (filters["mainCategories"]) {
                            const mainCat = filters["mainCategories"].find(cat => cat.id === value);
                            if (!mainCat) {
                                filters["mainCategories"].push(itemCatalog);
                            }
                        } else {
                            filters["mainCategories"] = [itemCatalog];
                        }
                        
                        if (!filters[value]) {
                            filters[value] = {
                                id: value,
                                name: itemCatalog.title,
                                availableItemsCount: filterCount[value].count,
                                values: []
                            }
                        } else {
                            filters[value]["availableItemsCount"] = filterCount[value].count
                        }

                        previousId = value;
                    } else {
                        const currentId = previousId + "#" + value;
                        let itemCatalog = { ...this.rootStore.itemCatalogStore.getItemCatalog(currentId) };

                        itemCatalog.previousId = previousId
                        itemCatalog.id = currentId;
                        itemCatalog.name = itemCatalog.title;
                        itemCatalog.availableItemsCount = filterCount[currentId].count;

                        if (!filters[currentId]) {
                            filters[previousId].values.push(itemCatalog);
                        }

                        if (idx + 1 < values.length) {
                            if (filters[currentId]) {
                                filters[currentId]["availableItemsCount"] = filterCount[currentId].count;
                            } else {
                                filters[currentId] = {
                                    id: currentId,
                                    name: itemCatalog.title,
                                    availableItemsCount: filterCount[currentId].count,
                                    values: []
                                }
                            }
                        }

                        previousId = currentId;
                    }
                })
            });

            if (filters["mainCategories"]) {
                filters["mainCategories"].sort((a, b) => a.availableItemsCount < b.availableItemsCount);
            }

            this.shopItemFilters = filters;
            this.GENERATING_SHOP_ITEM_FILTERS = false;
        } catch (error) {
            Monitoring.recordError(error);
        }
    }
}

export default ShopItemCategoryStore;