import React from "react";
import { inject, observer } from "mobx-react";
import { SkeletonTheme } from 'react-loading-skeleton';

import SmallHeaderSkeleton from "./SmallHeaderSkeleton";
import LargeHeaderSkeleton from "./LargeHeaderSkeleton";


const ShopHeaderSkeleton = ({ rootStore }) => {
    const { sessionStore } = rootStore;
    const { numColumns } = sessionStore;

    return (
        <SkeletonTheme ccolor="rgb(224,224,224,0.5)" highlightColor="#f7f7f7">
            {
                numColumns > 2
                ? <LargeHeaderSkeleton />
                :  <SmallHeaderSkeleton />
            }
        </SkeletonTheme>
    )
}

export default React.memo(inject("rootStore")(observer(ShopHeaderSkeleton)));
