import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from "./graphql";

const { Queries } = GraphQL;


export default {
    getShopById: async (id) => {
        const res = await API.graphql(graphqlOperation(Queries.getShopById, { id }));
        return res.data.getShopById
    },

    /**
     * Fetch shop information
     * 
     * @param {String} id shop identifier
     * @returns {ShopInfos} 
     */
     getShopInfosByShopId: async (id) => {
        const params = {
            id,
            limit: 12 // number of items to send
        }
        const res = await API.graphql(graphqlOperation(Queries.getShopInfosByShopId, params));
        return res.data.getShopInfosByShopId
    },
}