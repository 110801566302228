import React from "react";
import { View } from "react-native";

import ShopHeaderSkeleton from "../../molecules/ShopHeader/ShopHeaderSkeleton"
import ShopItemGridSkeleton from "../../molecules/ShopItemGrid/skeleton"

const ShopPageSkeleton = () => (
    <View>
        <ShopHeaderSkeleton />
        <ShopItemGridSkeleton />
    </View>
)

export default ShopPageSkeleton;