import gql from "graphql-tag";

export default gql`
  query getItemById($id: String! ) {
    getItemById(id: $id ) {
        itemId,
        title,
        description,
        price,
        images,
        shopId,
        status,
        category,
        city,
        countryCode,
        shopPhone,
        shopName
    }
  }
`;
