import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const {
    REACT_APP_SENTRY_DSN,
    REACT_APP_SENTRY_PROJECT_NAME,
    REACT_APP_SENTRY_TRACING_RATE,
    REACT_APP_VERSION,
    NODE_ENV
} = process.env;


class Monitoring {

    setup(history, routes) {
        const config  = {
            // Filtering Timeout error without stack trace coming Google Recaptcha dependency
            // https://github.com/getsentry/sentry-javascript/issues/2514
            beforeSend(event, hint) {
                if (hint.originalException === "Timeout") return null;
                return event;
            },
            dsn: REACT_APP_SENTRY_DSN,
            integrations: [
                new Integrations.BrowserTracing(
                    {
                        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                    }
                )
            ],
          
            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: parseFloat(REACT_APP_SENTRY_TRACING_RATE),
        }

        if (NODE_ENV !== "development") {
            config["release"] = REACT_APP_SENTRY_PROJECT_NAME + "@" + REACT_APP_VERSION;
        }

        Sentry.init(config);
    }

    attachProfiler(App) {
        return Sentry.withProfiler(App);
    }

    recordError(error) {
        Sentry.captureException(error);
    }
}

const monitoring = new Monitoring();

if (process.env.NODE_ENV === "development") {
    monitoring.recordError = console.log;
}


export default monitoring;


