import React, { lazy, Suspense, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Switch, Route } from "react-router-dom";
import { Content } from "native-base";
import { SkeletonTheme } from 'react-loading-skeleton';
import * as Sentry from '@sentry/react';
import { useLastLocation } from 'react-router-last-location';

import HomePage from "../components/pages/HomePage";
import ScrollToTop from "../components/pages/ScrollToTop";
import LoadingPageSkeleton from "../components/pages/LoadingPageSkeleton";
import AppHeader from "../components/molecules/AppHeader";
import AppHeaderSkeleton from "../components/molecules/AppHeader/skeleton";


const ShopPage = lazy(() => import("../components/pages/ShopPage"));
const ItemPage = lazy(() => import("../components/pages/ItemPage"));
const CartPage = lazy(() => import("../components/pages/CartPage"));
const OrderPage = lazy(() => import("../components/pages/OrderPage"));
const SavedItemPage = lazy(() => import("../components/pages/SavedItemPage"));


// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

const App = () => {

  const lastLocation = useLastLocation();

  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }

  const [ previousShopPath, setPreviousShopPath ] = useState('');
  const [ isGoingBack, setGoingBack ] = useState('');

  // Detect when user goes back and do not reload items on shop page
  window.onpopstate = () => {
    setGoingBack(true);
  }

  const lastLocationPath = lastLocation ? lastLocation.pathname : null

  useEffect(() => {
    if (lastLocation && lastLocation.pathname.startsWith('/shop')) {
      setPreviousShopPath(lastLocation.pathname)
    }
  }, [lastLocationPath])

  return (
    <SkeletonTheme color="rgb(224,224,224,0.5)" highlightColor="#f7f7f7">
      <div style={styles.container}>
        <Suspense style={styles.overflow} fallback={<AppHeaderSkeleton />}>
          <AppHeader />
        </Suspense>
        <Content contentContainerStyle={styles.content}>
          <Suspense style={styles.overflow} fallback={<LoadingPageSkeleton />}>
            <Switch style={styles.overflow}>
              
              <SentryRoute key="home" exact path="/">
                <HomePage />
              </SentryRoute>
              
              <SentryRoute style={styles.overflow}  key="shop" exact path="/shop/:shopId">
                <ShopPage isGoingBack={isGoingBack} previousShopPath={previousShopPath} />
              </SentryRoute>
              
              <SentryRoute key="item" exact path="/item/:itemCategory/:itemId">
                <ScrollToTop>
                  <ItemPage />
                </ScrollToTop>
              </SentryRoute>

              <SentryRoute key="order" exact path="/order/:orderId">
                <OrderPage />
              </SentryRoute>

              <SentryRoute key="cart" exact path="/cart">
                <CartPage />
              </SentryRoute>

              <SentryRoute key="saved-items" exact path="/saved-items">
                <SavedItemPage />
              </SentryRoute>

            </Switch>
          </Suspense>
        </Content>
      </div>
    </SkeletonTheme>
  );
}

const styles = {
  container: {
    position: 'absolute',
    left: '0px',
    width: '100%',
    overflow: 'hidden'
  },
  content: {
    flex: 1
  },
  overflow: { overflowY: 'auto' }
}

export default inject("rootStore")(observer(App));