import React from "react";
import { View, FlatList, Dimensions } from "react-native";
import { inject, observer } from "mobx-react";
import Skeleton from 'react-loading-skeleton';


const { width } = Dimensions.get('window');

const CartPerShopSkeleton = ({ rootStore }) => {

    const { sessionStore } = rootStore;
    const { numColumns } = sessionStore;

    const gridStyle = { width: numColumns > 2 ? 600 : width - 30 };
    const skeletonWidth = numColumns > 2 ? 600 : width - 30;
    const skeletonContainer = { padding: 10 };

    return (
        <View style={styles.container}>
            <FlatList
                style={gridStyle}
                numColumns={1}
                data={[{id: "1"},{id: "2"},{id: "3"},{id: "4"}]}
                keyExtractor={item => item.id}
                renderItem={() => {
                    return (
                        <View style={skeletonContainer}>
                            <Skeleton height={160} width={skeletonWidth} />
                        </View>
                    )
                }}
            />
        </View>
    )
}

const styles = {
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 50
    }
}

export default inject("rootStore")(observer(CartPerShopSkeleton));