import { observable, reaction, action, computed } from "mobx";
import CommonUtils from "../../utils/CommonUtils";
import Monitoring from "../../utils/Monitoring";


export default class Item {

    /**
     * Unique id of this Item
     */
    id = null;

    title = null;
    description = null;
    images = null;
    imagesURIs = [];
    uri = null;
    isAvailable = true;
    shopId = null;
    shopPhone = null;
    shopName = null;
    city = null;
    countryCode = null;
    category = null;
    isSaved = false;
    placeholderImageURI = null;

    @observable price = null;
    @observable formattedPrice = null;
    @observable countryName = null;

    store = null;

    /**
     * Disposer for the side effect that automatically
     * stores this Item, see @dispose.
     */
    saveHandler = null;

    constructor(store) {
        this.store = store;
        
        reaction(
            () => ({
                price: this.price,
                countryCode: this.countryCode
            }),
            async ({ countryCode, price }) => {
                try {
                    const countryInfos = await CommonUtils.getCountryInfos(countryCode)
                
                    this.countryName = countryInfos.countryName;
                    this.formattedPrice = this.generateFormattedPrice(price, countryInfos.currencyCode);
                } catch (error) {
                    Monitoring.recordError(error);
                }
            }
        )
    }

    @computed
    get asJson() {
        return {
            id: this.id,
            title: this.title,
            price: this.price,
            description: this.description,
            placeholderImageURI: this.placeholderImageURI,
            imagesURIs: this.imagesURIs,
            formattedPrice: this.formattedPrice,
            isAvailable: this.isAvailable,
            shopPhone: this.shopPhone,
            shopName: this.shopName,
            uri: this.uri,
            city: this.city,
            countryCode: this.countryCode,
            countryName: this.countryName,
            shopId: this.shopId,
            category: this.category,
            isSaved: this.store.rootStore.savedItemStore.hasItem(this.id)
        }
    }

    @action.bound
    updateFromJson(json) {
        const { itemId, title, price, category, description, images, status, shopId, shopPhone, shopName, city, countryCode } = json

        this.placeholderImageURI = CommonUtils.generatePlaceholderImageURI(images[0]);
        this.countryCode = countryCode;
        this.id = itemId;
        this.title = title;
        this.price = price;
        this.description = description;
        this.images = images;
        this.imagesURIs = [CommonUtils.generateCardImageURI(images[0])];
        this.shopPhone = shopPhone;
        this.shopName = shopName;
        this.city = city;
        this.shopId = shopId;
        this.category = category;
        this.uri = `/item/${category.split("#").join("-")}/${itemId}`;
        this.isAvailable = status === "AVAILABLE"
    }

    @action.bound
    generateAllImagesUris() {
        const imagesURIs = [];
        this.images.forEach(img => imagesURIs.push(CommonUtils.generateCardImageURI(img)));

        return imagesURIs;
    }

    generateFormattedPrice(price, countryCurrency) {
        const DEFAULT_LANGUAGE_TAG = "fr-CM";
        const params = {
            style: 'currency',
            currency: countryCurrency
        }

        return new Intl.NumberFormat(DEFAULT_LANGUAGE_TAG,params).format(this.price);
    }
}