export default {
    "wedding#bride": [
        { id: 'wedding-dress', title: 'Robe de Mariée',  icon: 'icon-wedding-dress-filled.png' },
        { id: 'bridesmaid-dress', title: 'Robe de Demoiselle d\'honneur',  icon: 'icon-bridesmaid-dress-filled.png' },
        { id: 'child-bridesmaid', title: 'Vêtements & Chaussures Mage',  icon: 'icon-child-bridesmaid-filled.png' },
        { id: 'veil', title: 'Voile Mariée', icon: 'icon-bridal-veil.png' },
        { id: 'lingerie', title: 'Lingerie',  icon: 'icon-lingerie-filled.png' },
        { id: 'bouquet', title: 'Bouquet de Fleurs',  icon: 'icon-bouquet-filled.png' },
        { id: 'box', title: 'Box de Mariée',  icon: 'icon-wedding-box-filled.png' }
    ],
    "wedding#groom": [
        { id: 'wedding-suit', title: 'Costume de Marié',  icon: 'icon-wedding-dress-filled.png' },
        { id: 'child-groom', title: 'Vêtements & Chaussures Page',  icon: 'icon-child-groom-filled.png' },
        { id: 'accessories', title: 'Accessoires Marié',  icon: 'icon-accessories-filled.png' }
    ]
}