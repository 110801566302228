import localforage from "localforage";

localforage.config({
    driver      : localforage.INDEXEDDB,
    name        : 'Vemet',
    version     : 1.0,
    storeName   : 'vemet', // Should be alphanumeric, with underscores.
    description : 'Vemet Marketplace'
});

const Database = {
    get: (key) => localforage.getItem(key),
    set: (key, value) => localforage.setItem(key,value)
}

export default Database;