import React, { useEffect } from "react";
import { View, Text } from "native-base";
import { inject, observer } from "mobx-react";

import RecentShopCard from "../../molecules/RecentShopCard";

const HomePage = ({ rootStore }) => {
    const { recentShopStore } = rootStore;
    const { recentShops } = recentShopStore;

    useEffect(() => {
        window.sessionStorage.setItem('lastViewItemPosition', 0)
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.titleContainer}>
                <Text style={styles.title}>Boutiques visitées récemment</Text>
            </View>
            {
                recentShops.map(recentShop => (
                    <RecentShopCard
                        key={recentShop.id}
                        shopId={recentShop.id}
                        shopName={recentShop.name}
                        city={recentShop.city}
                        countryName={recentShop.countryName}
                        logoUri={recentShop.logoUri}
                    />
                ))
            }
        </View>
    )
} 

const styles = {
    container: {
        flexGrow: 1,
        marginHorizontal: 'auto',
        alignItems: 'center',
        marginVertical: 50
    },
    emptyContent: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    emptyContentText: {
        fontSize: 25,
        fontWeight: "bold"
    },
    title: {
        fontSize: 20,
        fontFamily: "system-ui",
        fontWeight: 'bold'
    },
    titleContainer: {
        paddingBottom: 30
    }
}

export default inject('rootStore')(observer(HomePage));