import Fashion from "./fashion";
import Wedding from "./wedding";
import Beauty from "./beauty";


export default {
    mainCategories: [
        { id: 'fashion', title: 'Mode', description: "Vêtements, Chaussures, Accessoires, Montres & Bijoux" },
        { id: 'beauty', title: 'Beauté et Bien-être', description: "Maquillage, Perruque, Parfums, Barbe" },
        // { id: 'home', title: 'Maison', description: "Meuble, Electroménager, Literie, Deco, Linge" },
        { id: 'luggage', title: 'Bagageries', description: "Sacs, valises, accessoires de voyage" },
        { id: 'wedding', title: 'Mariage', description: "Univers du mariage" }
    ],

    fashion: [
        { id: 'woman', title: 'Femme' },
        { id: 'man', title: 'Homme' },
        // { id: 'baby', title: 'Bébé' },
        // { id: 'child', title: 'Enfant' },
        // { id: 'teenager', title: 'Adolescent' }
    ],

    beauty: [
        { id: 'woman', title: 'Femme' },
        { id: 'man', title: 'Homme' }
    ],

    // home: [
    //     { id: 'furniture', title: 'Meuble' },
    //     { id: 'home-appliance', title: 'Electroménager' },
    //     { id: 'household-linen', title: 'Linge de Maison' },
    //     { id: 'bedding', title: 'Literie' },
    //     { id: 'decoration', title: 'Décoration' },
    //     { id: 'baby', title: 'Puericulture (Bébé)' },
    //     { id: 'child', title: 'Enfant' }
    // ],

    wedding: [
        { id: 'bride', title: 'La Mariée' },
        { id: 'groom', title: 'Le Marié' },
        { id: 'accessories', title: 'Accessoires de Mariage' },
    ],

    luggage: [
        { id: 'bag', title: 'Sacs',  icon: 'icon-luggage-bag-filled.png' },
        { id: 'suitcase', title: 'Valises',  icon: 'icon-suitcase-filled.png' },
        { id: 'accessories', title: 'Accessoires de Voyage',  icon: 'icon-travel-accessories-filled.png' },
    ],

    "luggage#suitcase": [
        { id: 'small', title: 'Petite taille' },
        { id: 'medium', title: 'Taille moyenne' },
        { id: 'large', title: 'Grande taille' }
    ],
    
    ...Fashion,
    ...Wedding,
    ...Beauty
}