import { Storage } from "aws-amplify";

Storage.configure({
    level: 'public'
});

class StorageAPI {
    
    async getImage(s3Key) {
        return await Storage.get(s3Key);
    }
}


export default new StorageAPI();