import { Dimensions } from "react-native";

const { width } = Dimensions.get('window');
const DEVICE_WINDOW_WIDTH = 1100;

const styles = {
    appName: {
        fontWeight: "bold",
        fontSize: "26px",
        color: "#fff",
        marginBottom: 0,
        letterSpacing: 2
    },
    header: {
        backgroundColor: "#2d2d2d",
        height: 55
    },
    headerContainer: {
        alignItems: 'center',
        backgroundColor: "#2d2d2d"
    },
    badge: {
        position: 'absolute',
        borderRadius: 5,
        borderWidth: 1.5,
        borderColor: "#2d2d2d",
        padding: 4,
        right: 15,
        top: 13,
        backgroundColor: "#268848"
    }
}

if (width > DEVICE_WINDOW_WIDTH) {
    styles.appName.fontSize = "30px";
    styles.header.height = "7vh";
}

if (width > 1000 && width < 1100) {
    styles.appName.fontSize = "30px";
    styles.headerContainer.paddingHorizontal = 30;
} 

export default styles;