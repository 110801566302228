import { observable, action, computed, reaction } from "mobx";

import Monitoring from "../../utils/Monitoring";
import CommonUtils from "../../utils/CommonUtils";


/**
 * Manages data about displayed shop
 */
class ShopStore {

    rootStore = null;

    api = null;

    @observable id = null;
    @observable name = null;
    @observable logo = null;
    @observable description = null;
    @observable city = null;
    @observable phone = null;
    @observable countryCode = null;
    @observable countryName = null;
    @observable currencyCode = null;
    @observable currencyName = null;
    @observable SHOP_NOT_FOUND = false;
    @observable LOADING_SHOP = false;
    @observable availableItemsCount = 0;

    constructor(rootStore, api) {
        this.rootStore = rootStore;
        this.api = api;

        reaction(
            () => this.countryCode,
            async countryCode => {
                const countryInfos = await CommonUtils.getCountryInfos(countryCode);

                this.countryName = countryInfos.countryName;
                this.currencyCode = countryInfos.currencyCode;
                this.currencyName = countryInfos.currencyName;
            }
        )
    }

    @action.bound
    async loadShop(shopId) {
        try {
            this.LOADING_SHOP = true;

            const result = await this.api.Shop.getShopInfosByShopId(shopId);

            if (result) {
                this.updateFromJson(result.shop);
                this.rootStore.itemStore.setItems(result.items);
                this.rootStore.shopItemCategoryStore.setShopItemCategories(result.itemCategories);

                this.SHOP_NOT_FOUND = false;
            } else {
                this.SHOP_NOT_FOUND = true;
            }

            this.LOADING_SHOP = false;
        } catch (error) {
            Monitoring.recordError(error);
        }
    }


    @action.bound
    updateFromJson(json) {
        try {
            const { shopId, shopName, city, logo, description,  countryCode, phone, availableItemsCount } = json;

            this.id = shopId
            this.name = shopName;
            this.logo = logo && logo !== 'None' ? logo : null;
            this.logoUri = logo && logo !== 'None' ? CommonUtils.generateImageURI(logo) : null;
            this.description = description;
            this.city = city;
            this.phone = phone;
            this.countryCode = countryCode;
            this.availableItemsCount = availableItemsCount;

        } catch (error) {
            Monitoring.recordError(error);
        }
    }

    @computed get asJson() {
        return {
            id: this.id,
            name: this.name,
            city: this.city,
            logoUri: this.logoUri,
            description: this.description,
            countryCode: this.countryCode,
            countryName: this.countryName,
            currencyCode: this.currencyCode,
            currencyName: this.currencyName,
            availableItemsCount: this.availableItemsCount,
            phone: this.phone
        }
    }
}

export default ShopStore;