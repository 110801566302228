import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from "./graphql";

const { Queries } = GraphQL;

export default {
    getItemsByShopId: (shopId, nextToken) => {
        return new Promise(async (resolve, reject) => {
            API.graphql(graphqlOperation(Queries.getItemsByShopId, { id: shopId, nextToken, limit: 12 }))
                .then(result => {
                    resolve(result.data.getItemsByShopId)
                })
                .catch(err => {
                    reject(err.errors)
                });
        })
    },

    getItemById: (productId) => {
        return new Promise(async (resolve, reject) => {
            API.graphql(graphqlOperation(Queries.getItemById, { id: productId }))
                .then(result => {
                    resolve(result.data.getItemById)
                })
                .catch(err => reject(err.errors));
        })
    },

    getItemsByShopByCategory: async (shopId, category, nextToken) => {
        const result = await API.graphql(graphqlOperation(Queries.getItemsByShopByCategory, { id: shopId, category, nextToken, limit: 12  }));
        return result.data.getItemsByShopByCategory;
    },
}