import Auth from "./Auth";
import Shop from "./Shop";
import Storage from "./Storage";
import Item from "./Item";
import Order from "./Order";
import ShopItemCategory from "./ShopItemCategory";

export default {
    Auth,
    Shop,
    Storage,
    Item,
    Order,
    ShopItemCategory
}