import React from "react";
import { View, Dimensions } from "react-native";
import { inject, observer } from "mobx-react";
import Skeleton from 'react-loading-skeleton';


import styles from "./styles";

const { width } = Dimensions.get('window');

const ItemDetailSkeleton = ({ rootStore }) => {
    const { sessionStore } = rootStore;
    const { getCarouselDimensions, numColumns } = sessionStore;

    const carouselDimensions = getCarouselDimensions();

    if (numColumns === 2) {
        styles.contentStyle.flexDirection = 'column'
        styles.productDetailContainer = {};
        styles.productDetailStyle = {
            ...styles.productDetailStyle,
            width,
            marginLeft: 0
        }
    } else {
        styles.contentStyle = {
            marginTop: 40,
            flexDirection: 'row',
            justifyContent: 'center'
        }
    }

    return (
        <View style={styles.contentStyle}>
            <Skeleton {...carouselDimensions} />
            
            <View style={styles.productDetailStyle}>
                <View style={{ padding: 10 }}>
                    <View style={{ marginBottom: 10 }}>
                        <Skeleton width={200} height={25} />
                    </View>
                    <p>
                        <Skeleton {...styles.titleStyle} count={2} />
                    </p>
                </View>

                <View 
                    style={{
                        paddingHorizontal: 15,
                        borderTopWidth: 2,
                        borderColor: "#eee",
                        paddingVertical: 10,
                        borderBottomWidth: 2
                    }}
                >
                    <View style={{ paddingVertical: 5 }}>
                        <Skeleton width={styles.buttonStyle.width} height={40} />
                    </View>
                    <View style={{ paddingVertical: 5 }}>
                        <Skeleton width={styles.buttonStyle.width} height={40} />
                    </View>
                    <View style={{ paddingVertical: 5 }}>
                        <Skeleton width={styles.buttonStyle.width} height={40} />
                    </View>
                    
                </View>
            </View>
        </View>                   
    )
};

export default inject("rootStore")(observer(ItemDetailSkeleton));