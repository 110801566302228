import React from "react";
import { View } from "react-native";
import { inject, observer } from "mobx-react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';


const LargeHeaderSkeleton = ({ rootStore }) => {
    const { sessionStore } = rootStore;
    const { cardDimensions } = sessionStore;
  
    const contentStyle = { ...styles.content, width: cardDimensions.width * 2 }

    return (
        <SkeletonTheme ccolor="rgb(224,224,224,0.5)" highlightColor="#f7f7f7">
            <View style={styles.container}>
                <View style={contentStyle} >
                    <Skeleton circle height={130} width={130} />
                    <View style={styles.container_2}>
                        <View>
                            <Skeleton height={30} width={315} />
                            <View style={styles.location}>
                                <Skeleton height={10} width={150} />
                            </View>
                            <View style={styles.items}>
                                <Skeleton height={10} width={150} />
                            </View>
                        </View>
                        <View style={styles.contact}>
                            <View style={styles.container_5}>
                                <Skeleton height={30} width={150}/>
                            </View>
                            <View>
                                <Skeleton height={30} width={150}/>
                            </View>
                        </View>
                        <View style={styles.description}>
                            <View style={styles.container_7}>
                                <Skeleton height={15} width={315}/>
                            </View>
                            <View>
                                <Skeleton height={15} width={315}/>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </SkeletonTheme>
    )
}

const styles = {
    container: {
        alignItems: 'center',
        marginVertical: 30
    },
    content: {
        flexDirection: 'row'
    },
    container_2: {
        marginLeft: 20
    },
    location: {
        marginTop: 8
    },
    items: {
        marginTop: 0
    },
    contact: {
        marginTop: 10,
        flexDirection: 'row',
    },
    container_5: {
        marginRight: 10
    },
    description: {
        marginTop: 20
    },
    container_7: {
        marginRight: 15
    }
}

export default inject("rootStore")(observer(LargeHeaderSkeleton));
