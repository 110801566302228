import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from "./graphql";

const { Queries } = GraphQL;

export default {
    
    getShopItemCategoriesByShopId: async (shopId, nextToken) => {
        const result = await API.graphql(graphqlOperation(Queries.getShopItemCategoriesByShopId, { id: shopId, nextToken }));
        
        if (result 
                && result.data.getShopItemCategoriesByShopId 
                && result.data.getShopItemCategoriesByShopId.items.length) {
            return result.data.getShopItemCategoriesByShopId.items;
        } else {
            return [];
        }
    }
}