import React from "react";
import { FlatList, View } from "react-native";
import { inject, observer } from "mobx-react";
import Skeleton from 'react-loading-skeleton';

const MemoizedSkeleton = React.memo(Skeleton);
const MemoizedFlatList = React.memo(FlatList);

const ItemListSkeleton = ({ rootStore }) => {
  const { sessionStore } = rootStore;
  const { numColumns, cardDimensions } = sessionStore
  return (
    <View style={{ flex: 1, flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
      <MemoizedFlatList
        contentContainerStyle={{ alignItems: 'center' }}
        numColumns={numColumns}
        data={[{id: "1"},{id: "2"},{id: "3"},{id: "4"},{id: "5"},{id: "6"},{id: "7"},{id: "8"},{id: "9"},{id: "10"},{id: "11"}, {id: "12"}]}
        keyExtractor={item => item.id}
        renderItem={() => (
          <View>
            <View style={{ marginRight: 10, marginBottom: 5 }}>
              <MemoizedSkeleton width={cardDimensions.width} height={cardDimensions.height} />
            </View>
            <View style={{ marginRight: 10, marginBottom: 10 }}>
              <MemoizedSkeleton width={cardDimensions.width} height={25} />
            </View>
          </View>
          )
        }
        ListHeaderComponent={() => (
          <View style={{ width: (cardDimensions.width + 10) * numColumns }}>
            <MemoizedSkeleton width={cardDimensions+5 * 4} height={43} />
            <View style={{ marginVertical: 15, alignItems: 'center' }}>
              <MemoizedSkeleton width={120} height={15} />
            </View>
          </View>
        )}
        ListHeaderComponentStyle={{ marginBottom: 15 }}
      />
    </View>
  );
};

export default React.memo(inject("rootStore")(observer(ItemListSkeleton)));