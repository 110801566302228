export default {

  productDetailStyle: {
    marginLeft: 5,
    width: 280
  },

  productDetailContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 40
  },

  titleStyle: {
    fontSize: 20,
    color: "#808080",
    textDecorationLine: null,
    fontFamily: 'system-ui',
    lineHeight: 26,
    fontWeight: "400"
  },

  priceStyle: {
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: 'system-ui'
  },

  descriptionContainer: {
    marginTop: 15,
    padding: 10 
  },
  
  descriptionTitleStyle: {
    fontSize: 17,
    fontWeight: "bold",
    fontFamily: 'system-ui'
  },
  
  descriptionMessageStyle: {
    fontSize: 15,
    paddingTop: 5,
    fontFamily: 'system-ui'
  },
  
  buttonTextStyle: {
    fontWeight: "700",
    fontFamily: 'system-ui',
    letterSpacing: 2,
    lineHeight: 24,
    fontSize: 14
  },

  buttonStyle: {
    borderRadius: 4,
    justifyContent: 'center',
    backgroundColor: "#268848"
  },

  disabledButtonStyle: {
    borderRadius: 4,
    justifyContent: 'center',
    backgroundColor: "gray"
  },

  contentStyle: {}
};