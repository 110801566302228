import { toJS, action, observable, reaction, set } from "mobx";

import Monitoring from "../../utils/Monitoring";
import Database from "../../utils/Database";
import CATALOG from "./Catalog";

class ItemCatalogStore {
    rootStore = null;
    api = null;

    @observable firstRun = true;
    @observable recentCategories = [];

    constructor(rootStore, api) {
        this.rootStore = rootStore;
        this.api = api;

        reaction(
            () => this.recentCategories.length,
            async () => {
                if (this.firstRun) {
                    Database
                        .get("recentCategories")
                        .then(res => {
                            const existingStore = res;
                            
                            if (existingStore) {
                                set(this, existingStore);
                            }

                            this.firstRun = false;
                        })
                        .catch(err => {
                            Monitoring.recordError(err);
                            this.firstRun = false;
                        })
                } else {
                    const { recentCategories } = toJS(this);

                    // keeps only the last 4 categories
                    if (recentCategories.length > 4){
                        this.recentCategories.pop();
                    }

                    Database.set("recentCategories", { recentCategories });
                }
            },
            {
                fireImmediately: true
            }
        )
    }

    @action.bound
    addToRecentCategory(category) {
        const res = this.recentCategories.slice().find(item => item.id === category);
        
        if (!res) {
            this.recentCategories.unshift({
                id: category,
                path: this.getCatalogPath(category)
            });
        }
    }

    getMainCategories() {
        return CATALOG["mainCategories"];
    }

    getCatalog(catalogId) {
        return CATALOG[catalogId];
    }

    @action.bound
    getCatalogPath(catalogId, prevPath) {
        const catalogParts = catalogId.split("#");
        const idToLookup = catalogParts.pop();
        let newPath = '';

        if (!catalogParts.length) {
            this.getMainCategories().forEach(category => {
                if (category.id === idToLookup) {
                    newPath = prevPath ? category.title + " / " + prevPath : category.title;
                }
            });

            return newPath;

        } else {
            const nextCatalogId = catalogParts.join("#");
            this.getCatalog(nextCatalogId).forEach(category => {
                if (category.id === idToLookup) {
                    newPath = prevPath ? category.title + " / " + prevPath : category.title;
                }
            });

            return this.getCatalogPath(nextCatalogId, newPath);
        }
    }

    getItemCatalog(catalogId) {
        const Ids = catalogId.split("#");
        let res;

        if (Ids.length === 1) {
            res = CATALOG["mainCategories"].find(item => item.id === catalogId);
        } else {
            const idToFind = Ids.pop();
            res = CATALOG[Ids.join("#")].find(item => item.id === idToFind)
        }

        return res;
    }
}

export default ItemCatalogStore;