import { observable, action } from "mobx";

/**
 * Manages filter for a shop
 */
class ShopFilterStore {

  rootStore = null;
  api = null;
  
  @observable shopId = null;
  @observable LOADING_SHOP_CATEGORIES = false;
  @observable shopCategories = null;
  @observable shopProductTypes = null;
  @observable showFilter = false;

  @observable globalFilters = [
    {
      id: "Category",
      title: "Catégorie"
    },
    {
      id: "ProductType",
      title: "Type de Produit"
    }
  ];

  @observable mainFilters = [{
      id: "woman#fashion",
      title: "Femme"
    },
    {
      id: "man#fashion",
      title: "Homme"
    },
    {
      id: "both#fashion",
      title: "Mixte"
    }
  ];

  constructor(rootStore, api) {
    this.rootStore = rootStore;
    this.api = api;
  }

  @action.bound
  setShowFilter(value) {
    this.showFilter = value;
  }

  fetchShopCategories() {
    return [{
        category: "woman#fashion#shoes#highheel",
        availableItemsCount: 2
      },
      {
        category: "woman#fashion#shoes#basket",
        availableItemsCount: 2
      },
      {
        category: "woman#fashion#shoes#ballerin",
        availableItemsCount: 2
      },
      {
        category: "woman#fashion#clothes#top-body",
        availableItemsCount: 10
      }
    ]
  }

  @action.bound
  getShopCategories(globalCategoryId) {
    this.LOADING_SHOP_CATEGORIES = true
    const resultShopCategories = this.fetchShopCategories(globalCategoryId);
    this.generateReadableFilters(globalCategoryId, resultShopCategories);
    this.LOADING_SHOP_CATEGORIES = false;
  }

  @action.bound
  generateReadableFilters(globalCategoryId, resultShopCategories) {
    // fetch shop categories from server
    const groupShopCategories = {};
    const shopProductTypes = [];
    
    resultShopCategories.forEach(ShopCategory => {
      const res =  ShopCategory.category.split(globalCategoryId)[1].split("#")
      const categoryId =res[1];
      const productTypeId = res[2];
      
      shopProductTypes.push({
        ...productTypes[productTypeId],
        category: categoryId,
        count: ShopCategory.availableItemsCount
      });
      
      if (groupShopCategories[categoryId]) {
        groupShopCategories[categoryId].count = groupShopCategories[categoryId].count + ShopCategory.availableItemsCount
      } else {
        groupShopCategories[categoryId] = {
          ...productCategories[categoryId],
          count: ShopCategory.availableItemsCount
        }
      }
    });

    this.shopCategories = Object.values(groupShopCategories);
    this.shopProductTypes = shopProductTypes;
  }
}

export default ShopFilterStore;


const productCategories = {
	clothes: {
    id: 'clothes',
    title: 'Vêtements',
    icon: 'icon-shirt-outline.png'
  },
  shoes: {
    id: 'shoes',
    title: 'Chaussures',
    icon: 'icon-shoes-outline.png'
  },
  accessories: {
    id: 'accessories',
    title: 'Accessoires',
    icon: 'icon-bag-outline.png'
  },
  jewelry: {
    id: 'jewelry',
    title: 'Montres & Bijoux',
    icon: 'icon-watch-outline.png'
  }
}

const productTypes = {
    "man-underwear": { title: 'Sous-vêtements', id: 'man-underwear', icon: 'icon-man-underwear-filled.png' },
    "shirt": { title: 'Chemise', id: 'shirt', icon: 'icon-shirt-filled.png' },
    "jean": { title: 'Jean', id: 'jean', icon: 'icon-jean-filled.png' },
    "trouser": { title: 'Pantalon', id: 'trouser', icon: 'icon-trouser-filled.png' },
    "polo": { title: 'Polo', id: 'polo', icon: 'icon-polo-filled.png' },
    "t-shirt": { title: 'T-Shirt', id: 't-shirt', icon: 'icon-tshirt-filled.png' },
    "tailor-set": { title: 'Costume & Tailleur', id: 'tailor-set', icon: 'icon-tailor-filled.png' },
    "legging": { title: 'Legging', id: 'legging', icon: 'icon-legging-filled.png'  },
    "skirt": { title: 'Jupe', id: 'skirt', icon: 'icon-skirt-filled.png' },
    "dress": { title: 'Robe', id: 'dress', icon: 'icon-dress-filled.png' },
    "wedding-dress": { title: 'Robe de Mariée', id: 'wedding-dress', icon: 'icon-wedding-dress-filled.png' },
    "top-body": { title: 'Top & Body', id: 'top-body', icon: 'icon-woman-body-filled.png' },
    "woman-underwear": { title: 'Lingerie & Pyjamas', id: 'woman-underwear', icon: 'icon-woman-underwear-filled.png' },
    "slip": { title: 'Culotte', id: 'slip', icon: 'icon-woman-slip-filled.png' },
    "bra": { title: 'Soutien-Gorge', id: 'bra', icon: 'icon-bra-filled.png' },
    "bra-slip": { title: 'Ensemble Sous-Vêtements', id: 'bra-slip', icon: 'icon-bikini-filled.png' },
    "nightie": { title: 'Nuisette', id: 'nightie', icon: 'icon-nightie-filled.png' },
    "pyjama": { title: 'Pyjama', id: 'pyjama', icon: 'icon-pyjama-filled.png' },
    "combination": { title: 'Combinaison', id: 'combination',  icon: 'icon-pyjama-filled.png' },
    "swimsuit": { title: 'Maillot de Bain', id: 'swimsuit', icon: 'icon-woman-swimsuit-filled.png' },
    "mocassin": { title: 'Mocassins', id: 'mocassin', icon: 'icon-mocassin-outline.png' },
    "basket": { title: 'Basket', id: 'basket', icon: 'icon-basket-filled.png' },
    "ankleboot": { title: 'Bottines', id: 'ankleboot', icon: 'icon-ankleboot-filled.png' },
    "derby": { title: 'Derbie', id: 'derby', icon: 'icon-shoes-filled.png' },
    "highheel": { title: 'A Talons', id: 'highheel', icon: 'icon-highheel-filled.png' },
    "ballerin": { title: 'Ballerines', id: 'ballerin', icon: 'icon-ballerin-filled.png' },
    "tie": { title: 'Cravate', id: 'tie', icon: 'icon-tie-filled.png' },
    "bowknot": { title: 'Noeud Papillon', id: 'bowknot', icon: 'icon-bowknot-filled.png' },
    "pocket-square": { title: 'Mouchoir de poche', id: 'pocket-square' },
    "cap": { title: 'Casquette', id: 'cap', icon: 'icon-cap-filled.png' },
    "hat": { title: 'Chapeau', id: 'hat', icon: 'icon-hat-filled.png' },
    "belt": { title: 'Ceinture', id: 'belt', icon: 'icon-belt-filled.png' },
    "socks": { title: 'Chaussettes', id: 'socks', icon: 'icon-socks-filled.png' },
    "bag": { title: 'Sac', id: 'bag', icon: 'icon-bag-filled.png' },
    "backpack": { title: 'Sac à dos', id: 'backpack', icon: 'icon-backpack-filled.png' },
    "sunglasses": { title: 'Lunettes de soleil', id: 'sunglasses', icon: 'icon-sunglasses-filled.png' },
    "wallet": { title: 'Portefeuille', id: 'wallet', icon: 'icon-wallet-filled.png' },
    "hat-church": { title: 'Chapeau Eglise', id: 'hat-church', icon: 'icon-hat-filled.png' },
    "scarf": { title: 'Foulard', id: 'scarf', icon: 'icon-scarf-filled.png' },
    "watch": { title: 'Montres', id: 'watch', icon: 'icon-watch-filled.png' },
    "collier": { title: 'Collier', id: 'necklace', icon: 'icon-necklace-filled.png' },
    "bracelet": { title: 'Bracelets', id: 'bracelet', icon: 'icon-bracelet-filled.png' },
    "ring": { title: 'Bagues', id: 'ring', icon: 'icon-ring-filled.png' },
    "earring": { title: 'Boucles d\'oreilles', id: 'earring', icon: 'icon-earring-filled.png' }
}