import { action } from "mobx";

/**
 * Manages user data
 */
class UserStore {

    rootStore = null;

    api = null;

    constructor(rootStore, api) {
        this.rootStore = rootStore;
        this.api = api;
        this.signIn();
    }

    @action.bound signIn() {
        return this.api.Auth.signInAsGuest();
    }
}

export default UserStore;