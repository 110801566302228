export default {
    
    "beauty#man": [
        { id: "face-and-body", title: "Visage & Corps" },
        { id: "hair", title: "Soin des cheveux" },
        { id: "body", title: "Soins du corps" },
        { id: "perfume", title: "Parfums" }
    ],

    "beauty#man#face-and-body": [
        { id: "mower", title: "Tondeuse" },
        { id: "razor", title: "Rasoir" },
        { id: "shaving-gel", title: "Gel et Crème de rasage" },
        { id: "shaving-kit", title: "Kit de rasage" },
        { id: "aftershave", title: "Lotion après rasage" },
        { id: "beard-kit", title: "Kit entretien de barbe" },
        { id: "beard-oil", title: "Baume et Huile de barbe" },
        { id: "beard-comb", title: "Peigne et Brosse de barbe" }
    ],

    "beauty#man#hair": [
        { id: "comb", title: "Peignes" },
        { id: "wax", title: "Cire & Pomade" },
        { id: "shampoo", title: "Shampoing" },
        { id: "after-shampoo", title: "Après Shampoing" }
    ],

    "beauty#man#body": [
        { id: "shower-gel", title: "Gel douche" },
        { id: "oil-cream", title: "Crème et Huile pour le corps" },
        { id: "deodorant", title: "Déodorant" }
    ],

    "beauty#man#perfume": [
        { id: "perfume", title: "Eau de parfum" },
        { id: "cologne", title: "Eau de toilette" }
    ]
}