import getShopById from "./getShopById";
import getItemsByShopId from "./getItemsByShopId";
import getItemById from "./getItemById";
import getOrderById from "./getOrderById";
import getShopItemCategoriesByShopId from "./getShopItemCategoriesByShopId";
import getItemsByShopByCategory from "./getItemsByShopByCategory";
import getShopInfosByShopId from "./getShopInfosByShopId";

export default {
    getShopById,
    getItemsByShopId,
    getItemById,
    getOrderById,
    getShopItemCategoriesByShopId,
    getItemsByShopByCategory,
    getShopInfosByShopId
}