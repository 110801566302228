import { Auth } from "aws-amplify";


class AuthApi {
    signInAsGuest() {
        return Auth.currentCredentials();
    }
}

export default new AuthApi();
