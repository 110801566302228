import React from "react";
import { View, Dimensions } from "react-native";
import { inject, observer } from "mobx-react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const { width } = Dimensions.get('window');

const buttonWidth = width/2 - 15
const descriptionWidth = width - 20

const SmallHeaderSkeleton = () => (
    <SkeletonTheme ccolor="rgb(224,224,224,0.5)" highlightColor="#f7f7f7">
        <View style={styles.container}>
            <Skeleton circle height={100} width={100} />
            <View style={styles.shopName}>
                <Skeleton height={20} width={200} />
            </View>
            <View style={styles.container_3}>
                <Skeleton height={10} width={100} />
                <Skeleton height={10} width={100} />
            </View> 
            <View style={styles.contact}>
                <View style={{ marginRight: 10 }}>
                    <Skeleton height={30} width={buttonWidth}/>
                </View>
                <Skeleton height={30} width={buttonWidth}/>
            </View>
        </View>
        <View style={styles.description}>
            <Skeleton height={10} width={descriptionWidth}/>
            <Skeleton height={10} width={descriptionWidth}/>
        </View>
    </SkeletonTheme>
)

const styles = {
    container: {
        alignItems: 'center',
        paddingVertical: 20,
        width
    },
    container_1: {
        flexDirection: 'row'
    },
    shopInfos: {
        marginTop: 10,
        alignItems: 'center'
    },
    shopName: {
        margin: 10
    },
    container_3: {
        marginBottom: 8
    },
    contact: {
        paddingLeft: 0,
        flexDirection: 'row'
    },
    description: {
        paddingHorizontal: 10,
        marginBottom: 20
    }
}


export default inject("rootStore")(observer(SmallHeaderSkeleton));
