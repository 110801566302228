import { observable, computed, action } from "mobx";


export default class ShopItemCategory {

    /**
     * Unique id of this category
     */
    id = null;

    @observable categoryId = null;
    @observable name = null;
    @observable icon = null;
    @observable availableItemsCount = null;

    store = null;

    constructor(store) {
        this.store = store;
    }

    @computed get asJson() {
        return {
            categoryId: this.categoryId,
            name: this.name,
            icon: this.icon,
            availableItemsCount: this.availableItemsCount
        }
    }

    getCategoryItemFromCatalog(categoryId) {
        const rest = categoryId.substring(0, categoryId.lastIndexOf("#"));
        const last = categoryId.substring(categoryId.lastIndexOf("#") + 1, categoryId.length);

        const catalog = this.store.rootStore.itemCatalogStore.getCatalog(rest);
        const category = catalog.find(item => item.id === last);

        return category;
    }


    @action.bound
    updateFromJson(json) {
        const { category, availableItemsCount } = json
        const categoryItem = this.getCategoryItemFromCatalog(category);
        const { title, icon, filterName } = categoryItem;

        this.categoryId = category;
        this.name = filterName ? filterName : title;
        this.icon = icon;
        this.availableItemsCount = availableItemsCount;
    }
}