import React from "react";
import { Text, View } from "react-native";
import { Header, Left, Right } from "native-base";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { inject, observer } from "mobx-react";

import styles from "./styles";

const AppHeaderSkeleton = ({ rootStore }) => {

  const { sessionStore } = rootStore;
  const { cardDimensions,  numColumns } = sessionStore;

  const headerWidth = (
    numColumns > 3 
    ? cardDimensions.width * numColumns + 80
    : cardDimensions.width * numColumns + 30
  )

  const headerStyle = {...styles.header, width: headerWidth }

  return (
    <View style={styles.headerContainer}>
      <Header style={headerStyle}>
        <Left >
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Text style={styles.appName}>Vemet</Text>
          </Link>
        </Left>
        
        <Right style={{ marginRight: 10 }}>
            <Skeleton width={60} height={30} />
        </Right>
      </Header>
    </View>
  )
};

export default inject("rootStore")(observer(AppHeaderSkeleton));
