import gql from "graphql-tag";

export default gql`
  mutation createOrder(
    $shopId: String!
    $countryCode: String!
    $city: String!
    $shopPhone: String!
    $shopName: String!
    $totalPrice: Int!
    $items: [OrderItemInput!]!
  ) {
    createOrder(
      order: {
        shopId: $shopId
        countryCode: $countryCode
        city: $city
        shopPhone: $shopPhone
        shopName: $shopName
        totalPrice: $totalPrice
        items: $items
      }
    ) {
      orderId
    }
  }
`;

