import gql from "graphql-tag";

export default gql`
  query getItemsByShopByCategory($id: String!, $category: String!, $nextToken: String, $limit: Int) {
    getItemsByShopByCategory(id: $id, category: $category, nextToken: $nextToken, limit: $limit) {
      nextToken
      items {
        itemId,
        title,
        description,
        price,
        images,
        category,
        shopId,
        status,
        city,
        countryCode,
        shopPhone,
        shopName
      }
    }
  }
`;
