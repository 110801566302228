import UserStore from "./UserStore";
import ItemStore from "./ItemStore";
import ShopStore from "./ShopStore";
import CartStore from './CartStore';
import SessionStore from "./SessionStore";
import SavedItemStore from "./SavedItemStore";
import RecentItemStore from "./RecentItemStore"
import RecentShopStore from "./RecentShopStore"
import ShopFilterStore from "./ShopFilterStore";
import ItemCatalogStore from "./ItemCatalogStore";
import ShopItemCategoryStore  from "./ShopItemCategoryStore";

import API from "../api";

class RootStore {

    /**
     *  Middleware used to interact with server
    */
    api = null

    constructor(api) {
        this.api = api;
        this.userStore = new UserStore(this, api);
        this.sessionStore = new SessionStore();
        this.shopStore = new ShopStore(this, api);
        this.shopFilterStore = new ShopFilterStore(this, api);
        this.itemStore = new ItemStore(this, api);
        this.cartStore = new CartStore(this, api);
        this.savedItemStore = new SavedItemStore(this, api);
        this.recentItemStore = new RecentItemStore(this, api);
        this.recentShopStore = new RecentShopStore(this, api);
        this.itemCatalogStore = new ItemCatalogStore(this, api);
        this.shopItemCategoryStore = new ShopItemCategoryStore(this, api);
    }
}

export default new RootStore(API);