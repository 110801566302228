import gql from "graphql-tag";

export default gql`
  query getShopInfosByShopId($id: String!, $limit: Int) {
    getShopInfosByShopId(id: $id, limit: $limit) {
      shop {
        shopId
        shopName
        city
        logo
        description
        countryCode
        availableItemsCount
        phone
      }
      items {
        nextToken
        items {
          itemId
          price
          images
          status
          category
          countryCode
        }
      }
      itemCategories {
        nextToken
        items {
          category
          availableItemsCount
        }
      }
    }
  }
`;