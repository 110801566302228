import React, { lazy, Suspense } from "react";
import { inject, observer } from "mobx-react";

import AppHeaderSkeleton from "./skeleton";
const AppHeader = lazy(() => import("./component"));

const AppHeaderContainer = ({ rootStore }) => {
  const { savedItemStore } = rootStore;
  const { numberOfSavedItems } = savedItemStore;
  
  return (
    <Suspense fallback={<AppHeaderSkeleton />}>
      <AppHeader numberOfSavedItems={numberOfSavedItems} />
    </Suspense>
  );
}

export default inject("rootStore")(observer(AppHeaderContainer));
