import React from "react";
import { Text, Card, CardItem, Body, Left, Right } from "native-base";
import { inject, observer } from "mobx-react";
import IosArrowForward from '@meronex/icons/ios/IosArrowForward';
import { Link } from "react-router-dom";
import { Dimensions } from "react-native";

import Image from "../../atoms/Image";

const { width } = Dimensions.get('window');

const RecentShopCard = ({ rootStore, shopId, shopName, city, countryName, logoUri }) => {
    
    const { sessionStore } = rootStore;
    const { numColumns } = sessionStore;

    const gridContainerStyle = {
        width: numColumns > 2 ? 600 : width - 30,
        padding: numColumns > 2 ? 10 : 0
    }

    const imageStyle = !logoUri ? shopCardStyles.logoWithNoImage : {}
    
    return (
        <Link to={`/shop/${shopId}`} style={shopCardStyles.link}>
            <Card style={gridContainerStyle} >
                <CardItem>
                    <Left style={shopCardStyles.left}>
                        <Image
                            src={logoUri}
                            height={80}
                            width={80}
                            style={imageStyle}
                        />
                        <Body>
                            <Text style={shopCardStyles.title}>{shopName}</Text>
                            <Text style={shopCardStyles.location} note>{city}, {countryName}</Text>
                        </Body>
                    </Left>
                    <Right style={shopCardStyles.right}>
                        <IosArrowForward size={20} color="#000" />
                    </Right>
                </CardItem>
            </Card>
        </Link>
    )
}

const shopCardStyles = {
    title: {
        fontSize: 17,
        marginBottom: 5,
        fontFamily: "system-ui",
        fontWeight: 'bold'
    },
    link: {
        textDecoration: 'none'
    },
    location: {
        fontFamily: "system-ui"
    },
    left: {
        flex: 1,
        flexDirection: 'row'
    },
    right: {
        flex: 0
    },
    logoWithNoImage: {
        backgroundColor: '#f7f7f7',
        borderColor: '#f7f7f7',
        borderRadius: 40
    }
}

export default inject('rootStore')(observer(RecentShopCard));