export default {
    "fashion#woman": [
        { id: 'clothes', title: 'Vêtements' },
        { id: 'lingerie', title: 'Lingerie' },
        { id: 'shoes', title: 'Chaussures' },
        { id: 'accessories', title: 'Accessoires' },
        { id: 'bag', title: 'Sacs et Portefeuilles' },
        { id: 'jewelry', title: 'Montres et Bijoux' },
        { id: 'sport', title: 'Sport' }
    ],
    "fashion#woman#clothes": [
        { id: 'shirt', title: 'Chemise', icon: 'icon-shirt-filled.png' },
        { id: 'jean', title: 'Jeans', icon: 'icon-jean-filled.png' },
        { id: 'trouser', title: 'Pantalon', icon: 'icon-trouser-filled.png' },
        { id: 't-shirt', title: 'T-Shirt, Polo', icon: 'icon-tshirt-filled.png' },
        { id: 'tailor-set', title: 'Costume & Tailleur', icon: 'icon-tailor-filled.png' },
        { id: 'legging', title: 'Legging', icon: 'icon-legging-filled.png'  },
        { id: 'kimono', title: "Kimono" },
        { id: 'skirt', title: 'Jupe', icon: 'icon-skirt-filled.png' },
        { id: 'dress', title: 'Robe', icon: 'icon-dress-filled.png' },
        { id: 'short', title: 'Short', icon: 'icon-woman-short-filled.png' },
        { id: 'combi', title: 'Combinaisons', icon: 'icon-pyjama-filled.png' },
        { id: 'overalls', title: "Salopette" },
        { id: 'work', title: "Vêtements de Travail" }
    ],
    "fashion#woman#lingerie": [
        { id: 'slip', title: 'Culotte, shorty, string', icon: 'icon-woman-slip-filled.png' },
        { id: 'bra', title: 'Soutien-gorge', icon: 'icon-bra-filled.png' },
        { id: 'bra-slip', title: 'Ensemble Sous-vêtements', icon: 'icon-bra-filled.png' },
        { id: 'nightie', title: 'Nuisette', icon: 'icon-nightie-filled.png' },
        { id: 'pyjama', title: 'Pyjama',  icon: 'icon-pyjama-filled.png' },
        { id: 'top-body', title: 'Top & Body',  icon: 'icon-woman-body-filled.png' },
        { id: 'swimsuit', title: 'Maillot de Bain', icon: 'icon-woman-swimsuit-filled.png' },
        { id: 'accessories', title: 'Accessoires de Lingerie', description: 'bretelles, cache-teton', icon: 'icon-woman-swimsuit-filled.png' }
    ],
    "fashion#woman#shoes": [
        { id: 'basket', title: 'Baskets' },
        { id: 'boots', title: 'Bottines / Bottes' },
        { id: 'pump', title: 'Escarpins' },
        { id: 'derbies', title: 'Derbies' },
        { id: 'mocassin', title: 'Mocassins' },
        { id: 'ballerina', title: 'Ballerines' },
        { id: 'sandal', title: 'Sandales' },
        { id: 'espadrille', title: 'Espadrilles' },
        { id: 'slipper', title: 'Chaussons' },
        { id: 'highheel', title: 'Chaussures à Talons' },
        { id: 'accessories', title: 'Accessoires & Entretien de Chaussures' }
    ],
    "fashion#woman#accessories": [
        { id: 'bag', title: 'Sacs & Porte-feuille',  icon: 'icon-hat-filled.png' },
        { id: 'belt', title: 'Ceintures',  icon: 'icon-belt-filled.png' },
        { id: 'hair', title: 'Accessoires Cheveux',  icon: 'icon-hair-accessories-filled.png' },
        { id: 'sunglasses', title: 'Lunettes de soleil',  icon: 'icon-sunglasses-filled.png' },
        { id: 'hat', title: 'Chapeaux', icon: 'icon-hat-filled.png' }
    ],
    "fashion#woman#bag": [
        { id: 'handbag', title: 'Sacs' },
        { id: 'backpack', title: 'Sacs à dos' },
        { id: 'wallet', title: 'Portefeuilles' },
        { id: 'toiletry-bag', title: 'Trousses de toilette' },
        { id: 'makeup-bag', title: 'Trousses de maquillage' }
    ],
    "fashion#woman#jewelry": [
        { id: 'watch', title: 'Montres', icon: 'icon-watch-filled.png' },
        { id: 'necklace', title: 'Collier', icon: 'icon-necklace-filled.png' },
        { id: 'bracelet', title: 'Bracelets', icon: 'icon-bracelet-filled.png' },
        { id: 'ring', title: 'Bagues', icon: 'icon-bracelet-filled.png' },
        { id: 'brooch', title: 'Broches', icon: 'icon-bracelet-filled.png' },
        { id: 'piercing', title: 'Piercing', icon: 'icon-bracelet-filled.png' },
        { id: 'earring', title: 'Boucles d\'oreilles', icon: 'icon-earring-filled.png' },
        { id: 'storage-box', title: 'Boite à bijoux', icon: 'icon-storage-box-filled.png' }
    ],
    "fashion#woman#sport": [
        { id: 'clothes', title: "Vêtements de Sport" },
        { id: 'shoes', title: "Chaussures de Sport" },
        { id: 'accessories', title: "Accessoires de Sport" }
    ],
    "fashion#woman#sport#shoes": [
        { id: 'football', title: "Football" },
        { id: 'basketball', title: "BasketBall" },
        { id: 'running', title: "Running" },
        { id: 'others', title: "Autres Sport" }
    ]
}