export default {
    "fashion#man": [
        { id: 'clothes', title: "Vêtements" },
        { id: 'underwear', title: "Sous-Vêtements" },
        { id: 'shoes', title: "Chaussures" },
        { id: 'jewelry', title: "Montres & Bijoux" },
        { id: 'accessories', title: "Accessoires" },
        { id: 'sport', title: "Sport" },
    ],
    "fashion#man#clothes": [
        { id: 't-shirt', title: "T-Shirt, Polo" },
        { id: 'jean', title: "Jeans" },
        { id: 'trouser', title: "Pantalons" },
        { id: 'shirt', title: "Chemise" },
        { id: 'pull', title: "Pull, Gilet, Sweat" },
        { id: 'jacket', title: "Veste, Blazer" },
        { id: 'tailor-set', title: "Costume & Tailleur" },
        { id: 'swimsuit', title: 'Maillot de Bain', icon: 'icon-man-swimsuit-filled.png' },
        { id: 'short', title: 'Bermuda & Short',   icon: 'icon-man-short-filled.png' },
        { id: 'work', title: "Vêtements de Travail" }
    ],
    "fashion#man#underwear": [
        { id: 'boxer', title: "Boxer, Caleçon" },
        { id: 'tank-top', title: "Débardeur" }
    ],
    "fashion#man#shoes": [
        { id: 'basket', title: 'Baskets' },
        { id: 'boots', title: 'Bottines' },
        { id: 'derbies', title: 'Derbies, Richelieu' },
        { id: 'mocassin', title: 'Mocassins' },
        { id: 'espadrille', title: 'Espadrilles' },
        { id: 'slipper', title: 'Chaussons' },
        { id: 'accessories', title: 'Accessoires & Entretien de Chaussures' }
    ],
    "fashion#man#jewelry": [
        { id: 'watch', title: 'Montres', icon: 'icon-watch-filled.png' },
        { id: 'necklace', title: 'Collier', icon: 'icon-necklace-filled.png' },
        { id: 'bracelet', title: 'Bracelets', icon: 'icon-bracelet-filled.png' },
        { id: 'signet-ring', title: 'Chevalière', icon: 'icon-bracelet-filled.png' },
    ],
    "fashion#man#accessories": [
        { id: 'wallet', title: "Porte-feuille & Porte clés" },
        { id: 'belt', title: 'Ceintures, Bretelles',  icon: 'icon-belt-filled.png' },
        { id: 'sunglasses', title: 'Lunettes de soleil',  icon: 'icon-sunglasses-filled.png' },
        { id: 'hat', title: 'Chapeaux, Casquette', icon: 'icon-hat-filled.png' },
        { id: 'socks', title: 'Chaussettes',  icon: 'icon-hat-filled.png' },
        { id: 'tie', title: 'Cravates',  icon: 'icon-hat-filled.png' },
        { id: 'cufflinks', title: 'Boutons de Manchette' },
        { id: 'bow-tie', title: 'Noeud Papillon',  icon: 'icon-hat-filled.png' }
    ],
    "fashion#man#sport": [
        { id: 'clothes', title: "Vêtements", filterName: "Vêtements de Sport" },
        { id: 'shoes', title: "Chaussures", filterName: "Chaussures de Sport" },
        { id: 'accessories', title: "Accessoires de Sport" }
    ],
    "fashion#man#sport#shoes": [
        { id: 'football', title: "Football" },
        { id: 'basketball', title: "BasketBall" },
        { id: 'running', title: "Running" },
        { id: 'others', title: "Autres Sport" }
    ]
}