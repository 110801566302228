import React from 'react';
import { View } from 'react-native';
import { Spinner } from 'native-base';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Placeholder = ({ width, height}) => (
    <View style={{ backgroundColor: '#e9e9e9', width, height, justifyContent: 'center' }}>
        <Spinner color="#000" />
    </View>
)

const Image = ({ width, height, src, placeholderSrc, style }) => (
    <LazyLoadImage
        object-fit="contain"
        effect="blur"
        width={width}
        height={height}
        src={src}
        placeholder={<Placeholder width={width} height={height} />}
        placeholderSrc={placeholderSrc}
        style={style}
    />
);

export default Image;