import { API, graphqlOperation } from 'aws-amplify';
import GraphQL from "./graphql";

const { Mutations, Queries } = GraphQL;

export default {
    createOrder: async (order) => {
        const result = await API.graphql(graphqlOperation(Mutations.createOrder, order));
        return result.data.createOrder;
    },

    getOrderById: async (orderId) => {
        const result = await API.graphql(graphqlOperation(Queries.getOrderById, { id: orderId }));
        return result.data.getOrderById;
    }
}