import React from "react";
import { useLocation } from "react-router-dom";

import CartPageSkeleton from "../CartPage/skeleton";
import ShopPageSkeleton from "../ShopPage/skeleton";
import ItemPageSkeleton from "../ItemPage/skeleton";
import SavedItemPageSkeleton from "../SavedItemPage/skeleton";

const LoadingPageSkeleton = () => {
    const location = useLocation();
    const pathname = location.pathname;

    if (pathname.startsWith("/shop")) return <ShopPageSkeleton />
    if (pathname.startsWith("/item")) return <ItemPageSkeleton />
    if (pathname.startsWith("/saved-items")) return <SavedItemPageSkeleton />
    if (pathname.startsWith("/cart") || pathname.startsWith("/order")) return <CartPageSkeleton />
}

export default LoadingPageSkeleton;
